@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Manrope";
  src: url("/fonts/Manrope.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Manrope", "Inter", sans-serif;
  color: #373c3c;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (min-width: 1536px) {
  .dynamic-width {
    width: 18.21% !important;
  }
}

@media (min-width: 1440px) {
  .dynamic-width {
    width: 12.5%;
  }
}

::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
  visibility: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
*:active::-webkit-scrollbar-track,
*:focus::-webkit-scrollbar-track,
*:hover::-webkit-scrollbar-track {
  visibility: visible;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.scrollbar-sm *::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-sm *::-webkit-scrollbar-thumb {
  border: 1px solid #edf2f7;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.modal {
  position: absolute;
  bottom: 10%;
  left: 10%;
}

/* --- */

.mapContainer,
.mapBox {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.mapContainer .mapboxgl-popup-content {
  padding: 8px;
  background: transparent;
  position: relative;
}

.mapContainer .mapboxgl-popup-tip {
  border: none;
}

.mapContainer .mapboxgl-map {
  position: relative;
}

.mapContainer .mapboxgl-popup {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-modal-bg {
  background: rgba(1, 9, 30, 0.8);
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8H13.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.5V13.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8H13.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
}
.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 8H1L4.5 1L8 8Z' fill='%23373C3C' stroke='%23373C3C' stroke-width='1.5' stroke-linejoin='round'/%3E%3Cpath d='M1 8L8 8L4.5 15L1 8Z' stroke='%23373C3C' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
}

.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.mapboxgl-ctrl-group {
  box-shadow: none !important;
}

.single-select input {
  position: absolute;
}

.single-select.blend .select__control,
.multi-select.blend .select__control {
  padding: 0;
  border: none;
}

.react-draft-toolbar {
  border: none !important;
  border-bottom: 1px solid #e5e7eb !important;
  box-shadow: 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  padding: 4px 8px !important;
  display: flex !important;
  align-items: center !important;
}

.react-draft-toolbar div {
  margin: 0;
}
.recharts-legend-item-text {
  font-size: 10px;
  color: #afb1b1 !important;
}
.recharts-cartesian-axis-tick-value {
  font-size: 10px;
}

table {
  table-layout: fixed;
}

.carousel-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.navbar-multiselect .multi-select .select__control {
  color: #0055ff;
  height: 30px;
  min-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-multiselect .multi-select .select__placeholder {
  color: #0055ff;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  -webkit-box-align: end;
}
.multi-level-submenu {
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 1px;
}

.date-picker-wide-menu.react-datepicker {
  width: 400px;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper {
  margin-top: -5px !important;
}

